import React, { useState } from 'react';
import { Menu, X, Wallet, ArrowRight, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import WalletBackground from './WalletBackground';

// Update TutorialStep component
const TutorialStep = ({ number, title, description, isActive, onClick }) => (
  <div 
    className={`relative transition-all duration-500 transform ${isActive ? 'scale-105' : 'scale-100'} cursor-pointer`}
    onClick={onClick}
  >
    <div className={`absolute -inset-0.5 bg-gradient-to-r from-purple-600 via-pink-500 to-blue-500 rounded-lg blur ${isActive ? 'opacity-100' : 'opacity-30'} transition duration-1000`}/>
    <div className="relative bg-black/40 backdrop-blur-xl p-6 rounded-lg border border-white/10 hover:border-white/20 transition">
      {/* Existing content */}
      <div className="flex items-center gap-4 mb-4">
        <div className="flex items-center justify-center w-8 h-8 rounded-full bg-purple-500/20 text-purple-400">
          {number}
        </div>
        <h3 className="text-xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
          {title}
        </h3>
      </div>
      <p className="text-gray-300 leading-relaxed">
        {description}
      </p>
    </div>
  </div>
);

const WalletPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [copied, setCopied] = useState(false);

  const copyAssetId = () => {
    const assetId = "f090423b386cad6d7d64376397a5503026dc63ea164349c9998b5e3734ea2dce";
    navigator.clipboard.writeText(assetId).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const steps = [
    {
      title: "Create a Chia Wallet if You Don't Have One",
      description: (
        <span>
          Start by creating a new Chia wallet. Make sure to securely store your 24 word mnemonic phrase - this is your key to access your funds. Get Chia Wallet 
          <a 
            href="https://www.chia.net/buy-xch/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="ml-1 text-purple-400 hover:text-purple-300 underline"
          >
            here
          </a>
        </span>
      )
    },
    {
      title: "Receive MegaMeow",
      description: (
        <span>
          Generate a new receive address in your Chia wallet. This is where you'll receive your MegaMeow tokens from airdrops or trades. For more details, refer to the wallet guide{' '}
          <a 
            href="https://docs.chia.net/getting-started/wallet-guide/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-purple-400 hover:text-purple-300 underline"
          >
            here
          </a>
          .
        </span>
      )
    },
    {
      title: "Verify Balance",
      description: "Once received, your MegaMeow balance will appear in your Chia wallet. Remember to always verify the asset ID matches to ensure you're using the correct token."
    },
    {
      title: "Trade MegaMeow",
      description: (
        <span>
          Ready to start trading? You can trade using Chia's offer files or through the DEX platforms. Get started{' '}
          <a 
            href="https://www.chia.net/buy-xch/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-purple-400 hover:text-purple-300 underline"
          >
            here
          </a>
          . For your security, always verify the asset ID and carefully review all transaction details before confirmation.
        </span>
      )
    }
  ];

  return (
    <div className="min-h-screen relative text-white overflow-hidden">
      {/* Background Layer */}
      <div className="fixed inset-0 z-0">
        <WalletBackground />
      </div>

      {/* Content Layer */}
      <div className="relative z-10">
        {/* Navigation Bar */}
        <nav className="sticky top-0 z-50 bg-black/30 backdrop-blur-md border-b border-white/10">
          <div className="max-w-7xl mx-auto px-4">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <div className="relative group">
                  {/* Orbital ring animation */}
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full opacity-75 group-hover:animate-spin-slow blur"></div>
                  
                  {/* Power flow effect */}
                  <div className="absolute -inset-1 bg-gradient-to-r from-cyan-500 via-purple-500 to-blue-500 rounded-full opacity-0 group-hover:opacity-50 animate-pulse-fast blur-md"></div>
                  
                  {/* Energy burst effect */}
                  <div className="absolute inset-0 bg-gradient-to-tr from-purple-600/50 via-transparent to-blue-500/50 rounded-full animate-power-burst"></div>
                  
                  {/* Main logo */}
                  <img 
                    src="/logo.png" 
                    alt="Logo" 
                    className="relative h-8 w-8 sm:h-10 sm:w-10 rounded-full border border-white/10 z-10 transition-transform duration-300 group-hover:scale-110" 
                  />
                  
                  {/* Glow effect */}
                  <div className="absolute inset-0 bg-purple-500/20 rounded-full blur-xl group-hover:bg-purple-500/40 transition-colors duration-300"></div>
                </div>
                <span className="ml-2 text-xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                  MegaMeow Wallet
                </span>
              </div>
              
              {/* Desktop Navigation */}
              <div className="hidden md:flex items-center space-x-6">
                <Link to="/" className="text-gray-300 hover:text-white transition duration-300">Home</Link>
                <Link to="/missions" className="text-gray-300 hover:text-white transition duration-300">Missions</Link>
                <Link to="/wallet" className="text-gray-300 hover:text-white transition duration-300">Wallet</Link>
                <Link to="/roadmap" className="text-gray-300 hover:text-white transition duration-300">Roadmap</Link>
                <Link to="/token-allocation" className="text-gray-300 hover:text-white transition duration-300">Token Allocation</Link>
              </div>
              
              {/* Mobile menu button */}
              <div className="flex md:hidden items-center">
                <button
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                  className="text-gray-300 hover:text-white"
                >
                  {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                </button>
              </div>
            </div>
          </div>

          {/* Mobile Navigation */}
          {mobileMenuOpen && (
            <div className="md:hidden bg-black/95 backdrop-blur-xl">
              <div className="px-4 pt-2 pb-4 space-y-4">
                <Link to="/" className="block text-gray-300 hover:text-white transition duration-300 py-2">Home</Link>
                <Link to="/missions" className="block text-gray-300 hover:text-white transition duration-300 py-2">Missions</Link>
                <Link to="/wallet" className="block text-gray-300 hover:text-white transition duration-300 py-2">Wallet</Link>
                <Link to="/roadmap" className="block text-gray-300 hover:text-white transition duration-300 py-2">Roadmap</Link>
                <Link to="/token-allocation" className="block text-gray-300 hover:text-white transition duration-300 py-2">Token Allocation</Link>
              </div>
            </div>
          )}
        </nav>

        {/* Main Content */}
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
          {/* Hero Section */}
          <div className="text-center mb-16 pt-8">
            <div className="relative inline-block mt-4">
              <div className="absolute -inset-8 bg-violet-600/20 blur-3xl rounded-full"></div>
              <h1 className="relative z-10 text-5xl sm:text-6xl lg:text-7xl font-bold mb-8 bg-gradient-to-r from-violet-300 via-fuchsia-200 to-blue-300 bg-clip-text text-transparent animate-gradient-x">
                Wallet Setup
              </h1>
            </div>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto mt-4">
              Follow these simple steps to set up your wallet and start managing your MegaMeow tokens
            </p>
          </div>

          {/* Updated Token Display */}
          <div className="relative mb-16">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 via-pink-500 to-blue-500 rounded-lg blur opacity-30"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-lg border border-white/10">
              <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                <div className="relative w-32 h-32">
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full blur-xl opacity-50 animate-pulse"></div>
                  <img src="/logo.png" alt="MegaMeow Token" className="relative w-full h-full object-cover rounded-full" />
                </div>
                <div className="flex-1 text-center md:text-left">
                  <h2 className="text-2xl font-bold mb-2 bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                    MegaMeow Token
                  </h2>
                  <div className="flex items-center gap-2 mb-4">
                    <p className="text-gray-300 font-mono text-sm">
                      {/* Display partial ID */}
                      f090423b...ea2dce
                    </p>
                    <button
                      onClick={copyAssetId}
                      className="inline-flex items-center px-3 py-1 text-sm bg-purple-500/10 hover:bg-purple-500/20 rounded-full text-purple-300 transition-all"
                    >
                      {copied ? 'Copied!' : 'Copy ID'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tutorial Steps */}
          <div className="grid gap-6 relative">
            {steps.map((step, index) => (
              <TutorialStep
                key={index}
                number={index + 1}
                title={step.title}
                description={step.description}
                isActive={activeStep === index}
                onClick={() => setActiveStep(index)}
              />
            ))}
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-between mt-8">
            <button
              onClick={() => setActiveStep(Math.max(0, activeStep - 1))}
              className="px-6 py-2 bg-black/40 backdrop-blur-xl rounded-full border border-white/10 hover:border-white/20 transition disabled:opacity-50"
              disabled={activeStep === 0}
            >
              Previous
            </button>
            <button
              onClick={() => setActiveStep(Math.min(steps.length - 1, activeStep + 1))}
              className="px-6 py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full text-white font-medium hover:opacity-90 transition disabled:opacity-50"
              disabled={activeStep === steps.length - 1}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* ... existing styles ... */}
      <style jsx>{`
        @keyframes spin-slow {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
        
        @keyframes pulse-fast {
          0%, 100% { transform: scale(1); opacity: 0.5; }
          50% { transform: scale(1.1); opacity: 0.8; }
        }
        
        @keyframes power-burst {
          0% { transform: scale(0.95); opacity: 0; }
          50% { transform: scale(1.05); opacity: 0.5; }
          100% { transform: scale(0.95); opacity: 0; }
        }
        
        .animate-spin-slow {
          animation: spin-slow 8s linear infinite;
        }
        
        .animate-pulse-fast {
          animation: pulse-fast 2s ease-in-out infinite;
        }
        
        .animate-power-burst {
          animation: power-burst 3s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

export default WalletPage;