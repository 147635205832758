import React, { useEffect, useRef, useState } from 'react';
import { Menu, X, ArrowRight } from 'lucide-react';
import BlackHole from '../../BlackHole.js';
import { Link } from 'react-router-dom';
import FuturisticBackground from '../../FuturisticBackground.js';

const GlowingCard = ({ number, title, description, link, href }) => (
  <div className="relative group transform transition-all duration-500 hover:scale-105">
    <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 via-pink-500 to-blue-500 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-gradient-xy"/>
    
    <div className="relative bg-black/70 backdrop-blur-xl p-4 sm:p-6 rounded-lg border border-white/10 hover:border-white/20 transition h-full">
      <div className="text-purple-400 text-lg sm:text-xl font-bold mb-2">{number}</div>
      <h3 className="text-lg sm:text-xl font-bold text-white mb-4 bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
        {title}
      </h3>
      <p className="text-gray-300 mb-4 text-sm sm:text-base min-h-[60px]">
        {description}
      </p>
      <a href={href} className="text-purple-400 hover:text-pink-400 transition flex items-center gap-2 text-sm sm:text-base">
        {link} →
      </a>
    </div>
  </div>
);

const MegaMeowLandingPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="min-h-screen relative text-white overflow-hidden">
      <FuturisticBackground />
      
      {/* Navigation Bar */}
      <nav className="relative bg-black/50 backdrop-blur-md border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div className="relative">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full blur opacity-75 animate-pulse"/>
                <img src="/logo.png" alt="Logo" className="relative h-8 w-8 sm:h-10 sm:w-10 rounded-full border border-white/10" />
              </div>
              <span className="ml-2 text-lg sm:text-xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                MegaMeow
              </span>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-6">
              <Link to="/" className="text-gray-300 hover:text-white transition duration-300">Home</Link>
              <Link to="/missions" className="text-gray-300 hover:text-white transition duration-300">Missions</Link>
              <Link to="/wallet" className="text-gray-300 hover:text-white transition duration-300">Wallet</Link>
              <Link to="/roadmap" className="text-gray-300 hover:text-white transition duration-300">Roadmap</Link>
              <Link to="/token-allocation" className="text-gray-300 hover:text-white transition duration-300">Token Allocation</Link>
            </div>

            {/* Mobile menu button */}
            <div className="flex md:hidden items-center">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="text-gray-300 hover:text-white"
                aria-label="Toggle mobile menu"
              >
                {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {mobileMenuOpen && (
          <div className="md:hidden bg-black/95 backdrop-blur-xl">
            <div className="px-4 pt-2 pb-4 space-y-4">
              <Link to="/" className="block text-gray-300 hover:text-white transition duration-300 py-2">Home</Link>
              <Link to="/missions" className="block text-gray-300 hover:text-white transition duration-300 py-2">Missions</Link>
              <Link to="/wallet" className="block text-gray-300 hover:text-white transition duration-300 py-2">Wallet</Link>
              <Link to="/roadmap" className="block text-gray-300 hover:text-white transition duration-300 py-2">Roadmap</Link>
              <Link to="/token-allocation" className="block text-gray-300 hover:text-white transition duration-300 py-2">Token Allocation</Link>
            </div>
          </div>
        )}
      </nav>

      {/* Hero Section */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
        <div className="text-center mb-16">
          {/* Main heading with enhanced glow effect */}
          <div className="relative inline-block">
            <div className="absolute -inset-4 bg-violet-600/30 blur-3xl rounded-full"></div>
            <h1 className="relative text-5xl sm:text-6xl lg:text-7xl font-bold mb-8 text-white drop-shadow-[0_0_10px_rgba(255,255,255,0.3)]">
              <span className="bg-gradient-to-r from-violet-300 via-fuchsia-200 to-blue-300 bg-clip-text text-transparent animate-gradient-x">
                MegaMeow Token
              </span>
            </h1>
          </div>

          {/* Introduction paragraphs with improved readability */}
          <div className="max-w-4xl mx-auto space-y-6 text-lg">
            <div className="relative bg-black/40 backdrop-blur-md p-6 rounded-lg">
              <p className="text-white shadow-sm leading-relaxed">
                This token is a symbolic reward for every developer, farmer, and holder who has never lost faith on Chia. As a "Community Achievement Token" (CATs), it embodies the resilience, dedication, and passion of the Chia community.
              </p>
              
              <p className="text-white shadow-sm leading-relaxed mt-4">
                Its purpose is to unite and reward everyone contributing to the ecosystem's growth and innovation. This token will be used to celebrate key milestones—whether it's a major technological breakthrough, widespread adoption, or inspiring community achievements.
              </p>
              
              <p className="text-white shadow-sm leading-relaxed mt-4">
                We are also actively committed to developing innovative and useful blockchain applications based on Chia, making the most of its technical advantages.
              </p>
            </div>
          </div>

          {/* CTA Button with enhanced visibility */}
          <div className="mt-12">
            <Link
              to="/missions"
              className="inline-flex items-center px-8 py-3 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium shadow-lg hover:shadow-xl hover:from-purple-700 hover:to-blue-700 transform hover:scale-105 transition-all duration-200"
            >
              Explore Missions
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </div>

      {/* How to Get Section with improved contrast */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
        <div className="relative">
          <div className="absolute inset-0 bg-black/50 backdrop-blur-md rounded-lg -z-10"></div>
          
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 sm:mb-20 text-white drop-shadow-[0_0_10px_rgba(255,255,255,0.3)]">
            <span className="bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent animate-gradient-x">
              How to Get MegaMeow?
            </span>
          </h2>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          <GlowingCard 
            number="01"
            title="Airdrop"
            description="We will airdrop 5% of the total supply of the MegaMeow token within the first 180 days after launch."
            link="Get your share now"
            href="https://twitter.com/MegaMeowToken"
          />
          <GlowingCard 
            number="02"
            title="Trade P2P"
            description="Trade directly through Chia's offer file, the safest way to trade crypto without being scammed."
            link="See the list of decentralized exchanges"
            href="https://chialinks.com/exchanges/"
          />
          <GlowingCard 
            number="03"
            title="The True Game of Getting Tokens"
            description="Earn tokens when Chia hits its milestones and more."
            link="Coming Soon™"
          />
        </div>
      </div>
    </div>
  );
};

// Add custom animation classes
const customStyles = `
  @keyframes gradient-x {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  @keyframes gradient-xy {
    0% { background-position: 0% 0%; }
    25% { background-position: 100% 0%; }
    50% { background-position: 100% 100%; }
    75% { background-position: 0% 100%; }
    100% { background-position: 0% 0%; }
  }
  .animate-gradient-x {
    animation: gradient-x 15s ease infinite;
    background-size: 200% 200%;
  }
  .animate-gradient-xy {
    animation: gradient-xy 15s ease infinite;
    background-size: 200% 200%;
  }
`;

export default MegaMeowLandingPage;