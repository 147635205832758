import React from 'react';
import ReactDOM from 'react-dom/client';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import MegaMeowLandingPage from './Page/Landing/MegaMeowLandingPage';
import MissionsPage from './Page/missions/Missions';
import MissionOverview from './Page/missions/MissionOverview';
import TechnicalDevelopment from './Page/missions/TechnicalDevelopment';
import WalletPage from './Page/wallet/WalletPage';
import RoadmapPage from './Page/roadmap/RoadMap';
import TokenAllocationPage from './Page/token_allocation/TokenAllocation';
import TokenDetailsPage from './Page/token_allocation/TokenDetailsPage';
import TokenTrackerPage from './Page/token_allocation/TokenTrackerPage'; // Add this import
import reportWebVitals from './reportWebVitals';

const router = createHashRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <MegaMeowLandingPage />,
      },
      {
        path: 'missions',
        element: <MissionsPage />,
      },
      {
        path: 'missions/overview',
        element: <MissionOverview />,
      },
      {
        path: 'missions/technical',
        element: <TechnicalDevelopment />,
      },
      {
        path: 'missions/:id',
        element: <MissionOverview />,
      },
      {
        path: 'wallet',
        element: <WalletPage />,
      },
      {
        path: 'roadmap',
        element: <RoadmapPage />,
      },
      {
        path: 'token-allocation',
        element: <TokenAllocationPage />,
      },
      {
        path: 'token-details',
        element: <TokenDetailsPage />,
      },
      {
        path: 'token-tracker', // Add new route
        element: <TokenTrackerPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();