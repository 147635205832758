import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X, Copy, Check } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import FuturisticBackground from '../../FuturisticBackground';

const WalletCard = ({ address, title, description }) => {
  const [copied, setCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const copyAddress = () => {
    navigator.clipboard.writeText(address);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className="relative group"
    >
      {/* Ambient light effect */}
      <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 via-pink-500 to-blue-500 rounded-lg opacity-75 group-hover:opacity-100 blur transition duration-1000 group-hover:duration-200 animate-gradient-x"/>
      
      {/* Main card content */}
      <div className="relative flex flex-col h-full bg-black/80 backdrop-blur-xl p-6 rounded-lg border border-white/10 group-hover:border-white/20">
        {/* Title */}
        <h3 className="text-2xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent mb-3">
          {title}
        </h3>

        {/* Description */}
        <p className="text-gray-300 mb-6 flex-grow">
          {description}
        </p>

        {/* Address section with animation */}
        <motion.div 
          className="relative bg-black/50 p-4 rounded-lg border border-white/5 group-hover:border-white/10 transition-all"
          animate={{ scale: copied ? 1.02 : 1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <div className="flex items-center justify-between">
            <div className="overflow-hidden">
              <p className="text-gray-400 text-sm mb-1">Wallet Address:</p>
              <p className="text-gray-200 font-mono text-sm truncate">
                {address}
              </p>
            </div>
            <button
              onClick={copyAddress}
              className={`ml-4 p-2 rounded-full transition-all duration-300 ${
                copied 
                  ? 'bg-green-500/20 text-green-400' 
                  : 'bg-white/5 text-gray-400 hover:bg-white/10 hover:text-white'
              }`}
            >
              {copied ? <Check size={20} /> : <Copy size={20} />}
            </button>
          </div>
          
          {/* Copy feedback */}
          <AnimatePresence>
            {copied && (
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                className="absolute -top-8 right-0 text-green-400 text-sm"
              >
                Copied!
              </motion.p>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </motion.div>
  );
};

const TokenTrackerPage = () => {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Predefined wallet addresses with titles and descriptions
  const wallets = [
    {
      address: "xch1ja73pucrrnskfwv3a6xkslhrghnsyt3lz6n2nw42j0hp2h9jr0kqafzwgu",
      title: "IPO Momentum Wallet",
      description: "This wallet will only be used for events related to the Chia IPO."
    },
    {
      address: "xch1zfr0y7ty43qghfyfpppndmsnf9qd3tt59p6ha8svhupahwdzm43sg69c9t",
      title: "MegaMeow Cold Wallet I",
      description: "This is the first wallet that will be moved from cold wallet to warm wallet. Any movement from this wallet will be announced at least 14 days in advance."
    },
    {
      address: "xch1stcjy66ztgd7fjlqeqtaxpa6l93u2csg9z94qdlvsw2krnephvhqwvluet",
      title: "MegaMeow Cold Wallet II",
      description: "This is the second wallet that will be moved from cold wallet to warm wallet after the first cold wallet is depleted. Any movement from this wallet will be announced at least 14 days in advance."
    },
    {
      address: "xch1w34p2zqtaxd40ersplk3g508r4m4tcyw2wt00492ur0u5q9jp5nq4a5xhx",
      title: "Warm Wallet",
      description: "This wallet will be used for the initial airdrop, 6% milestone rewards, 2% for the core developer team, and 2% for the community developer team."
    }
  ];

  return (
    <div className="min-h-screen relative text-white">
      <FuturisticBackground />
      
      {/* Navigation Bar */}
      <nav className="relative bg-black/50 backdrop-blur-md border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div className="relative">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full blur opacity-75 animate-pulse"/>
                <img src="/logo.png" alt="Logo" className="relative h-8 w-8 sm:h-10 sm:w-10 rounded-full border border-white/10" />
              </div>
              <span className="ml-2 text-lg sm:text-xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                MegaMeow
              </span>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-6">
              <Link to="/" className="text-gray-300 hover:text-white transition duration-300">Home</Link>
              <Link to="/missions" className="text-gray-300 hover:text-white transition duration-300">Missions</Link>
              <Link to="/wallet" className="text-gray-300 hover:text-white transition duration-300">Wallet</Link>
              <Link to="/roadmap" className="text-gray-300 hover:text-white transition duration-300">Roadmap</Link>
              <Link to="/token-allocation" className="text-gray-300 hover:text-white transition duration-300">Token Allocation</Link>
            </div>

            {/* Mobile menu button */}
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="md:hidden text-gray-300 hover:text-white"
            >
              {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {mobileMenuOpen && (
          <div className="md:hidden bg-black/95 backdrop-blur-xl">
            <div className="px-4 pt-2 pb-4 space-y-4">
              <Link to="/" className="block text-gray-300 hover:text-white transition duration-300 py-2">Home</Link>
              <Link to="/missions" className="block text-gray-300 hover:text-white transition duration-300 py-2">Missions</Link>
              <Link to="/wallet" className="block text-gray-300 hover:text-white transition duration-300 py-2">Wallet</Link>
              <Link to="/roadmap" className="block text-gray-300 hover:text-white transition duration-300 py-2">Roadmap</Link>
              <Link to="/token-allocation" className="block text-gray-300 hover:text-white transition duration-300 py-2">Token Allocation</Link>
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
        {/* Header */}
        <div className="text-center mb-16">
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
            <div className="relative inline-block">
              <div className="absolute -inset-4 bg-violet-600/30 blur-3xl rounded-full"/>
              <span className="relative bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                Token Tracker
              </span>
            </div>
          </h1>
          <p className="text-gray-300 max-w-2xl mx-auto">
            Monitor our token allocations and movements
          </p>
        </div>

        {/* Add Spacescan.io Section */}
        <div className="text-center mb-16">
          <motion.a
            href="https://www.spacescan.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="relative inline-block group"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-600 to-blue-600 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-300"/>
            <div className="relative px-6 py-3 bg-black/70 backdrop-blur-xl rounded-lg border border-white/10 group-hover:border-white/20">
              <span className="text-lg font-semibold bg-gradient-to-r from-indigo-400 to-blue-400 bg-clip-text text-transparent">
                Track on Spacescan.io 
              </span>
              <p className="text-gray-400 text-sm mt-1">
                View Chia Blockchain Explorer
              </p>
            </div>
          </motion.a>
        </div>

        {/* Wallet Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {wallets.map((wallet) => (
            <WalletCard
              key={wallet.address}
              {...wallet}
            />
          ))}
        </div>

        {/* Back to Token Allocation Button - Moved here */}
        <div className="text-center">
          <motion.button
            onClick={() => navigate('/token-allocation')}
            className="relative inline-block group"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 via-pink-500 to-blue-500 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-300"/>
            <div className="relative px-6 py-3 bg-black/70 backdrop-blur-xl rounded-lg border border-white/10 group-hover:border-white/20">
              <span className="text-lg font-semibold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                ← Back to Token Allocation
              </span>
            </div>
          </motion.button>
        </div>
      </div>

      <style jsx>{`
        @keyframes gradient-x {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }

        .animate-gradient-x {
          animation: gradient-x 15s ease infinite;
          background-size: 200% 200%;
        }
      `}</style>
    </div>
  );
};

export default TokenTrackerPage;