import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { motion } from 'framer-motion';
import FuturisticBackground from '../../FuturisticBackground';

const OctagonSection = ({ percentage, title, description, isLarge, isActive, onClick, colorClass }) => (
  <motion.div
    className={`relative cursor-pointer ${isLarge ? 'col-span-2 row-span-2' : ''}`}
    initial={false}
    animate={{ scale: isActive ? 1.05 : 1 }}
    whileHover={{ scale: 1.05 }}
    onClick={onClick}
  >
    <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 via-pink-500 to-blue-500 rounded-lg blur opacity-30 group-hover:opacity-100 transition" />
    <div className={`
      relative h-full octagon ${colorClass}
      bg-black/70 backdrop-blur-xl p-6
      flex flex-col items-center justify-center text-center
      border border-white/10 hover:border-white/20 transition
    `}>
      <div className="text-4xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
        {percentage}%
      </div>
      <h3 className="text-xl font-semibold text-white mt-2 mb-3">{title}</h3>
      <p className="text-gray-300 text-sm">{description}</p>
    </div>
  </motion.div>
);

const TokenAllocationPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const navigate = useNavigate();

  const allocations = [
    {
      title: "IPO Commemoration",
      percentage: 35,
      description: "Reserved to commemorate Chia's IPO as a major milestone in the ecosystem.",
      isLarge: true,
      colorClass: "from-purple-600/20"
    },
    {
      title: "Milestone Rewards",
      percentage: 20,
      description: "Dedicated to celebrating milestones and rewarding community engagement.",
      colorClass: "from-pink-600/20"
    },
    {
      title: "Core Developer Reserve",
      percentage: 15,
      description: "Supporting ongoing development and maintenance of the MegaMeow ecosystem.",
      colorClass: "from-blue-600/20"
    },
    {
      title: "Community Developer",
      percentage: 10,
      description: "Set aside for community-driven projects and independent developers.",
      colorClass: "from-indigo-600/20"
    },
    {
      title: "Exchange Reserve",
      percentage: 10,
      description: "Supporting token listings and ensuring adequate market liquidity.",
      colorClass: "from-violet-600/20"
    },
    {
      title: "Initial Liquidity",
      percentage: 5,
      description: "Establishing foundational liquidity and community tribute.",
      colorClass: "from-teal-600/20"
    },
    {
      title: "Treasury Reserve",
      percentage: 5,
      description: "Strategic asset ensuring liquidity and long-term sustainability.",
      colorClass: "from-cyan-600/20"
    }
  ];

  return (
    <div className="min-h-screen relative text-white">
      <FuturisticBackground />
      
      {/* Navigation Bar */}
      <nav className="relative bg-black/50 backdrop-blur-md border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div className="relative">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full blur opacity-75 animate-pulse"/>
                <img src="/logo.png" alt="Logo" className="relative h-8 w-8 sm:h-10 sm:w-10 rounded-full border border-white/10" />
              </div>
              <span className="ml-2 text-lg sm:text-xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                MegaMeow
              </span>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-6">
              <Link to="/" className="text-gray-300 hover:text-white transition duration-300">Home</Link>
              <Link to="/missions" className="text-gray-300 hover:text-white transition duration-300">Missions</Link>
              <Link to="/wallet" className="text-gray-300 hover:text-white transition duration-300">Wallet</Link>
              <Link to="/roadmap" className="text-gray-300 hover:text-white transition duration-300">Roadmap</Link>
              <Link to="/token-allocation" className="text-gray-300 hover:text-white transition duration-300">Token Allocation</Link>
            </div>

            {/* Mobile menu button */}
            <div className="flex md:hidden items-center">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="text-gray-300 hover:text-white"
              >
                {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {mobileMenuOpen && (
          <div className="md:hidden bg-black/95 backdrop-blur-xl">
            <div className="px-4 pt-2 pb-4 space-y-4">
              <Link to="/" className="block text-gray-300 hover:text-white transition duration-300 py-2">Home</Link>
              <Link to="/missions" className="block text-gray-300 hover:text-white transition duration-300 py-2">Missions</Link>
              <Link to="/wallet" className="block text-gray-300 hover:text-white transition duration-300 py-2">Wallet</Link>
              <Link to="/roadmap" className="block text-gray-300 hover:text-white transition duration-300 py-2">Roadmap</Link>
              <Link to="/token-allocation" className="block text-gray-300 hover:text-white transition duration-300 py-2">Token Allocation</Link>
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
        {/* Header */}
        <div className="text-center mb-16">
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
            <div className="relative inline-block">
              <div className="absolute -inset-4 bg-violet-600/30 blur-3xl rounded-full"/>
              <span className="relative bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent animate-gradient-x">
                Token Allocation
              </span>
            </div>
          </h1>
          <p className="text-gray-300 max-w-2xl mx-auto">
            Strategic distribution designed to ensure sustainable growth and community engagement
          </p>
        </div>

        {/* Octagon Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative">
          {/* Central glow effect */}
          <div className="absolute inset-0 bg-gradient-to-r from-purple-900/20 via-pink-900/20 to-blue-900/20 blur-3xl -z-10"/>
          
          {allocations.map((allocation, index) => (
            <OctagonSection
              key={allocation.title}
              {...allocation}
              isActive={activeSection === index}
              onClick={() => setActiveSection(index)}
            />
          ))}
        </div>

        {/* More Details Button */}
        <div className="mt-12 text-center space-y-6">
          <motion.button
            onClick={() => navigate('/token-details')}
            className="relative inline-block group mr-4"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 via-pink-500 to-blue-500 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-300"/>
            <div className="relative bg-black/70 backdrop-blur-xl px-8 py-4 rounded-lg border border-white/10 group-hover:border-white/20 transition duration-300">
              <div className="text-2xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                More Details
              </div>
              <div className="text-gray-300 mt-1 text-sm">Click to learn more about our token allocation</div>
            </div>
          </motion.button>

          <motion.button
            onClick={() => navigate('/token-tracker')}
            className="relative inline-block group"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="absolute -inset-0.5 bg-gradient-to-r from-violet-600 via-indigo-500 to-purple-500 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-300"/>
            <div className="relative bg-black/70 backdrop-blur-xl px-8 py-4 rounded-lg border border-white/10 group-hover:border-white/20 transition duration-300">
              <div className="text-2xl font-bold bg-gradient-to-r from-violet-400 via-indigo-400 to-purple-400 bg-clip-text text-transparent">
                Track Tokens
              </div>
              <div className="text-gray-300 mt-1 text-sm">Monitor wallet addresses and token movements</div>
            </div>
          </motion.button>
        </div>
      </div>

      <style jsx>{`
        .octagon {
          clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
          aspect-ratio: 1;
        }

        @keyframes gradient-x {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }

        .animate-gradient-x {
          animation: gradient-x 15s ease infinite;
          background-size: 200% 200%;
        }
      `}</style>
    </div>
  );
};

export default TokenAllocationPage;